$primaryColor: #e2010e;
$bodyTextColor: #000;
$placeholderColor: #848484;
$lightgray: #ebeff0;
$white: #fff;
$black: #000;
$bodyFontSize: 16px;
$bodyLineHeight: 1.4;
$bodyFontFamily: 'Open Sans', sans-serif;
$headingFont: 'Open Sans', sans-serif;
$h1Size: 45px;
$h2Size: 34px;
$h3Size: 24px;
$h4Size: 20px;


@mixin resetUl {
  list-style: none;
  margin: 0;
  padding: 0;
}

$baseFontSize : $bodyFontSize;
@function r($pixels, $context: $baseFontSize) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return $pixels / $context * 1rem;
}